html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body::-webkit-scrollbar {
  width: 16px;
  border-radius: 16px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  width: 16px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 16px;
  background-clip: padding-box;  
}

div::-webkit-scrollbar {
  width: 16px;
  border-radius: 16px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
}

div::-webkit-scrollbar-thumb {
  width: 16px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 16px;
  background-clip: padding-box;  
}

a {
  /* color: inherit; */
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.goog-logo-link {
  display: inline-block;
}

.goog-logo-link img {
  display: inline-block;
}

img.transition {
  transition: 0.2s;
}

*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: var(--chakra-colors-brand-orange-600);
}

::selection {
  color: white;
  background: var(--chakra-colors-brand-orange-600);
}

.fancybox__container {
  z-index: 5000000000!important;
}

/* cards */

@media screen {
  @media (max-width: 767px) {
    .cards thead {
      display: none;
    }

    .cards tbody tr {
      float: left;
      margin: 10px;
      width: 95%;
      border: 1px solid #aaa;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
      background-color: white;
      overflow: hidden;
    }

    .cards tbody td {
      display: block;
      width: 100%;
      overflow: hidden;
      text-align: left;
    }
    .cards tbody td.visible-xs {
      display: block!important;
    }
  }
}

.donation-form input, .donation-form textarea, .donation-form select {
  border: 1px solid var(--chakra-colors-brand-orange-600);
  border-radius: 8px;
}

.donation-form input:focus-visible, .donation-form textarea:focus-visible, .donation-form select:focus-visible {
  outline-color: var(--chakra-colors-brand-orange-600);
}

.donation-form input.btn-submit-donation {
  border: 1px solid var(--chakra-colors-brand-darkgray-400);
  border-radius: 8px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  min-width: 300px;
  border-radius: 12px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.terms-and-conditions p,
.terms-and-conditions h1,
.terms-and-conditions h2,
.terms-and-conditions h3,
.terms-and-conditions h4,
.terms-and-conditions h5,
.terms-and-conditions h6{
  color: var(--chakra-colors-brand-darkgray-600);
  margin-bottom: 15px;
}
.terms-and-conditions table {
  width: 100%;
}

.terms-and-conditions table, 
.terms-and-conditions tr, 
.terms-and-conditions th, 
.terms-and-conditions td {
  border: 1px solid var(--chakra-colors-brand-darkgray-400);
  padding: 10px;
  color: var(--chakra-colors-brand-darkgray-600);
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.fundraising-pill::before {
  border-left-color: var(--chakra-colors-brand-darkgray-600)!important;
  right: -5px;
  border-width: 5px 0 5px 5px;
  top: 50%;
  margin-top: -5px;
  content: "";
  position: absolute!important;
  z-index: 3;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  content: "";
  display: block;
  z-index: 3;
}

.chatlio-title-bar {
  border: 2px solid white;
}

#userwayAccessibilityIcon {
  border: 2px solid black;
}

.cluster-marker {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 600;
  background: var(--chakra-colors-brand-orange-600);
  border: 1px solid var(--chakra-colors-brand-darkgray-600);
  border-radius: 50%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-arrow {
  filter: drop-shadow(0 4px 2px rgba(178,178,178,.4));
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1000;
}

.bottom-arrow:after {
  background: #fff;
  clip-path: polygon(0% 0%,50% 100%,100% 0%);
  content: '';
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}

#nprogress .bar {
  background: var(--chakra-colors-brand-green-600)
}

#nprogress .peg  {
  box-shadow: 0 0 10px var(--chakra-colors-brand-green-600), 0 0 5px var(--chakra-colors-brand-green-600);
}

#nprogress .spinner-icon {
  border-top-color: var(--chakra-colors-brand-green-600);
  border-left-color: var(--chakra-colors-brand-green-600);
}

.main-nav-link img {
  transition: 0.5s all ease-in-out;
}

.main-nav-link:hover img {
  transform: scale(1.5);
}

.rounded-img img {
  border-radius: 1rem;
  overflow: hidden;
}

.mini-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
  border: 2px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
}

.mini-scrollbar::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 8px;
  background-clip: padding-box;  
}

/* styles for google translate widget */
.goog-te-combo {
  border: 1px solid black;
}

.goog-te-gadget-icon {
  display: inline-block;
}

#goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{display: none !important;}

.goog-text-highlight { background: none !important; box-shadow: none !important;}

/* styles for google custom search */
.gsc-selected-option-container {
  max-width: 100% !important;
}
